@import '../theme-bootstrap';

.quicklinks-tout {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  height: 100%;
  padding: 5px;
  display: inline-block;
  width: 100%;
  @include breakpoint($bp--small-down) {
    display: block;
    margin: 0 auto;
    height: auto;
  }
  .quicklinks-tout__heading {
    border-bottom: none;
    margin-bottom: 15px;
    a {
      display: block;
      border-bottom: none;
      font-weight: normal;
      text-transform: uppercase;
      font-size: 60px;
      line-height: 0.1;
      margin-bottom: 0.15em;
      margin-top: 0.15em;
    }
    p {
      @include swap_direction(margin, 0);
    }
    @include breakpoint($bp--medium-down) {
      margin-top: 0;
      font-size: 2em;
      margin-bottom: 0px;
    }
  }
  .quicklinks-tout__content {
    @include swap_direction(padding, 0 20px);
    &-inner {
      padding-top: 15px;
    }
  }
  .quicklinks-tout__innerwrapper {
    display: block;
  }
  .quicklinks-tout__image {
    display: inline-block;
    width: 100%;
    .shade-image-overlay-wrapper {
      position: relative;
      .shade-image-overlay {
        background-color: $color--black;
        z-index: 10;
        position: absolute;
        width: 100%;
        height: 100%;
        opacity: 0;
        &.darken {
          opacity: 0.3;
        }
      }
      .add-to-bag-button {
        background-color: $color--black;
        width: 179px;
        height: 50px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        border-bottom: none;
        color: $color--white;
        line-height: 50px;
        font-family: $ano-bold_regular-font;
        font-size: 18px;
        cursor: pointer;
        z-index: 11;
        .add-to-bag-text:hover {
          text-decoration: underline;
        }
      }
    }
    @include breakpoint($bp--medium-down) {
      padding-top: 10px;
    }
  }
  .quicklinks-tout___over_img {
    position: absolute;
    width: 100%;
    text-align: center;
    color: white;
    overflow: hidden;
    z-index: 1;
    @include swap_direction(padding, 0 20px);
    a {
      color: white;
    }
    .header-img-over {
      font-weight: bold;
      text-transform: uppercase;
      font-size: 50px;
      line-height: 0.8;
      @include swap_direction(margin, 20px 0);
      text-align: center;
    }
    .quicklinks-tout__over_link {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      min-height: 400px;
      border: 0;
    }
  }
  .quicklinks-tout__image--top {
    padding-bottom: 10px;
  }
  /* Positioning Options */
  .quicklinks-tout__pos-top {
    vertical-align: top;
  }
  .quicklinks-tout__pos-middle {
    vertical-align: middle;
    .quicklinks-tout__image,
    .quicklinks-tout__innerwrapper {
      vertical-align: middle;
    }
  }
  .quicklinks-tout__pos-centered {
    text-align: center;
    position: relative;
  }
  .quicklinks-tout__pos-left {
    text-align: left;
  }
  .quicklinks-tout__pos-right {
    text-align: right;
  }
  /* When template is in the footer */
  .site-footer & {
    padding: 0;
    display: block;
    height: 2em;
    .quicklinks-tout__innerwrapper {
      display: block;
    }
    .quicklinks-tout__heading {
      margin: 0 0 5px 0;
      font-family: $body-font-family;
      a {
        font-size: 12px;
        display: inline-block;
        color: white;
        text-decoration: underline;
        padding: 0;
        margin: 0;
      }
    }
  }
}

.mobile-shade-image-cBox {
  #cboxClose {
    font-size: 1.8em;
    width: 35px;
  }
  .quicklinks-tout-mobile-colorbox-v1 {
    height: 100%;
    width: 100%;
    .mobile-colorbox-loader {
      width: 100%;
      height: 100%;
      position: relative;
      z-index: 10;
      background-color: $color--white;
    }
    .mobile-add-to-bag-shade-image {
      @include swap_direction(padding, 20px);
      margin-top: 12px;
    }
    .icon--remove {
      color: $color--gray--light;
      font-size: 19px;
    }
    .mobile-add-to-bag-prev-btn,
    .mobile-add-to-bag-next-btn {
      width: 30px;
      height: 30px;
      text-align: center;
      position: absolute;
      bottom: 57%;
      font-size: 15px;
      &:before {
        display: inline-block;
        margin-top: 50%;
        transform: translateY(-50%);
      }
    }
    .mobile-add-to-bag-prev-btn {
      #{$ldirection}: -12px;
    }
    .mobile-add-to-bag-next-btn {
      #{$rdirection}: -12px;
    }
    .mobile-add-to-bag-shade-name {
      font-family: $ano-bold_regular-font;
      font-size: 20px;
      font-weight: bold;
      letter-spacing: 0.59px;
      line-height: 25px;
      text-align: center;
      text-transform: uppercase;
      margin-bottom: 11px;
    }
    .mobile-add-to-bag-button {
      text-align: center;
      a.add-to-bag-button {
        background-color: $color--black;
        width: 179px;
        height: 50px;
        color: $color--white;
        line-height: 50px;
        font-family: $ano-bold_regular-font;
        font-size: 18px;
        text-align: center;
        display: inline-block;
      }
    }
    @include breakpoint($width-xsmall) {
      .mobile-add-to-bag-next-btn,
      .mobile-add-to-bag-prev-btn {
        font-size: 17px;
      }
    }
    @include breakpoint($width-small) {
      .mobile-add-to-bag-next-btn,
      .mobile-add-to-bag-prev-btn {
        font-size: 19px;
      }
    }
  }
}

.single-blocks-home {
  .singlerow-blocks-formatter__layout {
    @include swap_direction(margin, 0 0 20px 0);
    @include breakpoint($bp--medium-down) {
      @include swap_direction(margin, 0 0 20px);
    }
    &.layout-no-padding {
      .grid--item {
        @include swap_direction(padding, 0);
        .quicklinks-tout {
          @include swap_direction(padding, 0);
        }
      }
    }
    .grid--item {
      .quicklinks-tout__innerwrapper {
        @include breakpoint($bp--medium-down) {
          h2 {
            font-size: 28px;
          }
        }
        @include breakpoint($bp--small-down) {
          h2 {
            font-size: 28px;
          }
        }
      }
    }
    &.layout--25-25-25-25 {
      .grid--item {
        @include breakpoint($bp--medium-up) {
          width: 25%;
          float: #{$ldirection};
        }
      }
    }
  }
}

.quicklinks-tout {
  .flexible-3up-layout &,
  .flexible-grid-layout & {
    padding: 0;
    &__image,
    &__content-inner {
      padding: 0;
    }
  }
}
